// Loc
// export const baseUrl: string = 'http://192.168.1.182:8000'
// export const baseApiUrl: string = 'https://localhost:44309/api'
// export const hubUrl: string = 'https://localhost:44309/kixvuHub'


// Prod
export const baseUrl: string = 'https://www.kixvu.com'
export const baseApiUrl: string = 'https://kixvu-api-prod-f4gba5fffrfyhbf2.eastus2-01.azurewebsites.net/api'
export const hubUrl: string = 'https://kixvu-api-prod-f4gba5fffrfyhbf2.eastus2-01.azurewebsites.net/kixvuHub'

export const guestAccessToken: string = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiZ3Vlc3RAa2l4dnUuY29tIiwibmJmIjoiMTczNDM2MTU2OSIsImV4cCI6MTc2NTg5NzU2OSwic3ViIjoiZ3Vlc3RAa2l4dnUuY29tIiwianRpIjoiY2U4YWY5NDYtZjZlYi00ODU5LTg1MjctYjZjMWI3MjliYjhlIiwiU2NyZWVuTmFtZSI6Imd1ZXN0IiwiaXNzIjoiaHR0cHM6Ly93d3cua2l4dnUuY29tIiwiYXVkIjoiaHR0cHM6Ly93d3cua2l4dnUuY29tIn0.idGWc6ufXUK9Co7ODXUXkXB5jEZAxYWQPpczk9dAY6U'

export interface IResponse<T> {
    requestId: string
    responseId: string
    responseType: number
    result: T
    systemMessage?: string
    userMessage?: string
}
